// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-system-jsx": () => import("./../../../src/pages/design-system.jsx" /* webpackChunkName: "component---src-pages-design-system-jsx" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-downloading-js": () => import("./../../../src/pages/downloading.js" /* webpackChunkName: "component---src-pages-downloading-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-release-page-js": () => import("./../../../src/templates/release-page.js" /* webpackChunkName: "component---src-templates-release-page-js" */)
}

